<template>
  <div class="designer-new" v-loading.fullscreen.lock="loading">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <el-form class="form" ref="form" :model="form" size="small" label-width="180px">
      <el-form-item :label="$t('gpt.desc')">
        <el-input v-model="form.desc" size="small" style="width: 30%"></el-input>
      </el-form-item>
      <el-form-item :label="$t('gpt.sex')" required>
        <el-radio-group v-model="form.sex" @change="getOptions('deAge')">
          <el-radio v-for="item in sexList" :key="item.key" :label="item.key">
            {{ sexFilter(item.key) }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('gpt.age')" required>
        <el-select v-model="form.age" filterable>
          <el-option v-for="item in ageList" :key="item.key" :label="item.key" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('gpt.season')" required>
        <el-select v-model="form.season" filterable>
          <el-option v-for="item in seasonList" :key="item.key" :label="item.key" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('gpt.item')" required>
        <el-select v-model="form.t2" @change="changeT2" filterable>
          <el-option v-for="item in t2List" :key="item.key" :label="item.key" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('gpt.specificItem')" required>
        <el-select v-model="form.t3" filterable>
          <el-option v-for="item in t3List" :key="item.key" :label="item.key" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-for="item in trendPointList" :label="trendPointLabel(item.group)" :key="item.group">
        <el-select v-model="currentTrendPoints[item.group]" filterable clearable>
          <el-option v-for="key in item.keys" :key="key" :label="key" :value="key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('gpt.imageCount')" required>
        <el-input-number v-model="form.imageCount" :min="1" :max="10"></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-s-promotion" @click="onSubmit">{{ $t("common.submit") }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { createTask, fetchOptions } from "@/api/gpt";
import AiBreadcrumb from "@/components/AiBreadcrumb";

export default {
  components: {
    AiBreadcrumb,
  },
  data() {
    return {
      sexList: [],
      ageList: [],
      seasonList: [],
      t2List: [],
      t3List: [],
      trendPointList: [],
      currentTrendPoints: {},
      form: {
        desc: "",
        sex: "",
        age: "",
        season: "",
        t2: "",
        t3: "",
        // trendPoint: "",
        imageCount: 5,
      },
      loading: false,
      rules: {
        sex: [
          { required: true, message: "Please select sex", trigger: 'change' }
        ],
        t2: [
          { required: true, message: "Please select item", trigger: 'change' }
        ],
        t3: [
          { required: true, message: "Please select specific item", trigger: 'change' }
        ],
      }
    };
  },
  methods: {
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          let trendPoints = Object.values(this.currentTrendPoints);
          createTask({
            ...this.form, trendPoint: JSON.stringify(trendPoints), category: "designer"
          })
            .then((response) => {
              this.loading = false;
              if (response.success) {
                this.$notify({
                  title: this.$t("common.saveSucc"),
                  message: this.$t("common.indirecting"),
                  type: "success",
                });
                let suffix = response.result && response.result.id ? "/" + response.result.id : ""
                this.$router.push("/services/gpt-designer/designer" + suffix).catch(() => { });
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    changeT2() {
      this.currentTrendPoints = {};
      this.getOptions("deT3");
      this.getOptions("deTrendPoint");
    },
    getOptions(type) {
      let data = { "type": type }
      if (type === 'deAge') {
        data["sex"] = this.form.sex;
      } else if (type === 'deT3') {
        data["t2"] = this.form.t2;
        this.t3List = [];
      } else if (type === 'deTrendPoint') {
        data["t2"] = this.form.t2;
        this.trendPointList = [];
      }
      fetchOptions(data).then(response => {
        if (response.success) {
          if (type === "deSex") {
            this.sexList = response.result && response.result.items
              ? response.result.items : [];
            // 反转为 "W-M-CW-CM"
            this.sexList = this.sexList.reverse();
            this.form.sex = this.sexList.length > 0 ? this.sexList[0].key : "";
            this.getOptions("deAge");
          } else if (type === "deAge") {
            this.ageList = response.result && response.result.items
              ? response.result.items : [];
            this.form.age = this.ageList.length > 0 ? this.ageList[0].key : "";
          } else if (type === "deSeason") {
            this.seasonList = response.result && response.result.items
              ? response.result.items : [];
            this.form.season = this.seasonList.length > 0 ? this.seasonList[0].key : "";
          } else if (type === "deT2") {
            this.t2List = response.result && response.result.items
              ? response.result.items : [];
            this.form.t2 = this.t2List.length > 0 ? this.t2List[0].key : "";
            if (this.form.t2) {
              this.getOptions("deT3");
              this.getOptions("deTrendPoint");
            }
          } else if (type === "deT3") {
            this.t3List = response.result && response.result.items
              ? response.result.items : [];
            this.form.t3 = this.t3List.length > 0 ? this.t3List[0].key : "";
          } else if (type === "deTrendPoint") {
            if (response.result && response.result.items) {
              response.result.items.forEach(t => {
                for (let i = 0; i < this.trendPointList.length; i++) {
                  if (this.trendPointList[i].group === t.group) {
                    this.trendPointList[i].keys.push(t.key);
                    return;
                  }
                }
                this.trendPointList.push({
                  group: t.group,
                  keys: [t.key]
                })
              });
            }
            this.form.trendPoint = this.trendPointList.length > 0 ? this.trendPointList[0].key : "";
          }
        }
      })
    },
    sexFilter(sex) {
      const sexMap = {
        W: "women",
        M: "man",
        CW: "childGirl",
        CM: "childBoy",
      }
      if (sexMap[sex]) {
        return this.$t('gpt.' + sexMap[sex])
      }
      return sex
    },
    trendPointLabel(group) {
      if (group === 'Fit') {
        return this.$t('gpt.fit');
      } else if (group === 'Collar') {
        return this.$t('gpt.collar');
      } else if (group === 'Sleeve') {
        return this.$t('gpt.sleeve');
      } else if (group === 'Front') {
        return this.$t('gpt.front');
      } else if (group === 'Subsidiary Materials') {
        return this.$t('gpt.subsidiaryMaterials');
      } else if (group === 'Features') {
        return this.$t('gpt.features');
      } else if (group === 'Fabric') {
        return this.$t('gpt.fabric');
      } else if (group === 'Pattern') {
        return this.$t('gpt.pattern');
      } else if (group === 'Length') {
        return this.$t('gpt.length');
      } else if (group === 'Style') {
        return this.$t('gpt.style');
      } else {
        return group;
      }
    }
  },
  mounted() {
    this.getOptions("deSex");
    this.getOptions("deSeason");
    this.getOptions("deT2");
  }
};
</script>

<style lang="scss" scoped>
.designer-new {
  .form {
    margin-top: 25px;
  }
}
</style>